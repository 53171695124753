<template>
	<div class="content">
		<template v-if="loaded">
			<div class="header-section icon-list detailed">
				<div class="header-with-tabs row-space-tbf">
					<div class="space-left"></div>
					<div class="content">
						<slot />
						<div class="actions report-users" v-if="soft_organigram.is_active">
							<date-range-picker
								ref="picker"
								class="calendar-filter report-calendar"
								:class="[{ mobile: $resize && $mq.below(1593) }]"
								opens="left"
								:locale-data="calendarFilterSettings"
								:singleDatePicker="$mq.below(650) ? 'range' : false"
								:showWeekNumbers="false"
								:showDropdowns="false"
								:ranges="false"
								:autoApply="true"
								:linkedCalendars="true"
								v-model="dateRange"
							>
								<template v-slot:input="picker">
									<div class="input-picker">
										<div class="icon"><icon-date /></div>
										<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment("DD MMM YY") }}</div>
										<div class="date" v-else>{{ picker.startDate | moment("DD MMM YY") }} - {{ picker.endDate | moment("DD MMM YY") }}</div>
										<icon-arrow class="icon-arrow right" />
									</div>
								</template>
							</date-range-picker>

							<button class="btn-tbf grey filter-btn" v-bind:class="{ active: extendFilters, 'only-icon': $resize && $mq.below(1593) }" @click="extendFilters = !extendFilters">
								<template v-if="$resize && $mq.above(1594)">
									<div class="counter" v-bind:class="{ active: queryFiltersCount > 0 }">{{ queryFiltersCount }}</div>
									<span class="text">{{ $t("general.filters") }}</span>
									<div class="icon-filter">
										<icon-arrow />
									</div>
								</template>
								<div class="icon" v-else>
									<icon-settings />
								</div>
							</button>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</div>

			<div class="header-filter" v-if="soft_organigram.is_active">
				<div class="row-space-tbf filters-extend" :class="[{ mobile: ($resize && $mq.between([781, 905])) || ($resize && $mq.between([0, 682])) }]" v-if="extendFilters">
					<div class="space-left"></div>
					<div class="content">
						<!-- change activity type -->
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!activityType">tip activitate</span>
								<span class="text" v-else>{{ $t(`report_users.${activityType}`) }}</span>

								<div class="icon-filter"><icon-arrow /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<template v-for="activity in activitiesList">
									<div class="dropdown-item" @click="changeActivityType(activity)" v-bind:class="{ active: activityType.includes(activity) }" :key="'activity-type-' + activity">
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ $t(`report_users.${activity}`) }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<!-- filtrare dupa user -->
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedUsers.length">{{ $t("filters.by_user") }}</span>
								<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find((el) => el.id == selectedUsers[0]).name }}</span>
								<span class="text" v-else>{{ selectedUsers.length }} {{ $t("filters.users_selected") }}</span>

								<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')" />
								</div>

								<template v-for="user in filteredUsers">
									<div
										class="dropdown-item"
										@click.stop="selectOptionFilter('selectedUsers', user.id)"
										v-bind:class="{ active: selectedUsers.includes(user.id) }"
										:key="'filter-user-' + user.id"
									>
										<div class="checkbox">
											<div class="checkmark"></div>
											<span class="text">{{ user.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<!-- filtrare dupa status responsabilitati -->
						<template v-if="activityType == 'responsibilities'">
							<div class="filter-dropdown dropdown">
								<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedStatusResponsibilities.length">{{ $t("filters.by_status") }}</span>
									<span class="text" v-else-if="selectedStatusResponsibilities.length == 1">{{ $t(`report_users.statusTypes.${selectedStatusResponsibilities}`) }}</span>
									<span class="text" v-else>{{ selectedStatusResponsibilities.length }} {{ $t("filters.status_selected") }}</span>

									<div class="icon-filter" v-if="!selectedStatusResponsibilities.length"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedStatusResponsibilities')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<template v-for="status in statusListResponsibilities">
										<div
											class="dropdown-item"
											@click.stop="selectOptionFilter('selectedStatusResponsibilities', status)"
											v-bind:class="{ active: selectedStatusResponsibilities.includes(status) }"
											:key="'filter-status-' + status"
										>
											<div class="checkbox">
												<div class="checkmark"></div>
												<span class="text">{{ $t(`report_users.statusTypes.${status}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
						</template>
						<template v-else>
							<!-- filtrare dupa status taskuri -->
							<div class="filter-dropdown dropdown">
								<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedStatusTasks.length">{{ $t("filters.by_status") }}</span>
									<span class="text" v-else-if="selectedStatusTasks.length == 1">{{ $t(`report_users.statusTypes.${selectedStatusTasks}`) }}</span>
									<span class="text" v-else>{{ selectedStatusTasks.length }} {{ $t("filters.status_selected") }}</span>

									<div class="icon-filter" v-if="!selectedStatusTasks.length"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedStatusTasks')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<template v-for="status in statusListTasks">
										<div
											class="dropdown-item"
											@click.stop="selectOptionFilter('selectedStatusTasks', status)"
											v-bind:class="{ active: selectedStatusTasks.includes(status) }"
											:key="'filter-status-' + status"
										>
											<div class="checkbox">
												<div class="checkmark"></div>
												<span class="text">{{ $t(`report_users.statusTypes.${status}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
						</template>
						<!-- filtrare dupa departamente -->
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedDepartments.length">{{ $t("filters.by_departments") }}</span>
								<span class="text" v-else-if="selectedDepartments.length == 1">{{ filterDepartments.find((el) => el.id == selectedDepartments[0]).name }}</span>
								<span class="text" v-else>{{ selectedDepartments.length }} {{ $t("filters.departments_selected") }}</span>

								<div class="icon-filter" v-if="!selectedDepartments.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedDepartments')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchDepartments" :placeholder="$t('filters.search_department')" />
								</div>

								<template v-for="department in filteredDepartments">
									<div
										class="dropdown-item"
										@click.stop="selectOptionFilter('selectedDepartments', department.id, department)"
										v-bind:class="{ active: selectedDepartments.includes(department.id) }"
										:key="'filter-department-' + department.id"
									>
										<div class="checkbox" :class="`level-${department.level_depth}`" :style="`padding-left: calc(10px * ${department.level_depth});`">
											<div class="checkmark"></div>
											<span class="text">{{ department.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<!-- grupare dupa -->
						<template v-if="activityType == 'responsibilities'">
							<div class="filter-dropdown dropdown">
								<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="selectedGroupResp == ''">Grupare dupa</span>
									<span class="text" v-else>{{ $t(`report_users.group_byTypes.${selectedGroupResp}`) }}</span>

									<div class="icon-filter" v-if="!selectedGroupResp"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedGroupResp', 'string')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<template v-for="group in respGroupBy">
										<div
											class="dropdown-item"
											@click="selectSingleOptionFilter('selectedGroupResp', group)"
											v-bind:class="{ active: selectedGroupResp.includes(group) }"
											:key="'filter-status-' + group"
										>
											<div class="checkbox">
												<div class="checkmark"></div>
												<span class="text">{{ $t(`report_users.group_byTypes.${group}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="filter-dropdown dropdown">
								<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="selectedGroupTasks == ''">Grupare dupa</span>
									<span class="text" v-else>{{ $t(`report_users.group_byTypes.${selectedGroupTasks}`) }}</span>

									<div class="icon-filter" v-if="!selectedGroupTasks"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedGroupTasks', 'string')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<template v-for="group in tasksGroupBy">
										<div
											class="dropdown-item"
											@click="selectSingleOptionFilter('selectedGroupTasks', group)"
											v-bind:class="{ active: selectedGroupTasks.includes(group) }"
											:key="'filter-status-' + group"
										>
											<div class="checkbox">
												<div class="checkmark"></div>
												<span class="text">{{ $t(`report_users.group_byTypes.${group}`) }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>
						</template>
						<!-- buton sterge -->
						<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
							<div class="text" v-if="($resize && $mq.between([781, 905])) || ($resize && $mq.between([0, 682]))">{{ $t("filters.clear_all") }}</div>
							<icon-trash v-else />
						</button>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf search-row with-border">
					<div class="space-left"><icon-search v-if="$resize && $mq.above(781)" /></div>
					<div class="content">
						<div class="search-input">
							<input type="text" :placeholder="$t('general.search_by_name')" v-model="search_filter" v-debounce:700="searchFilterFunct" />
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
		</template>
		<template v-else>
			<loader-header-list />
			<loader-header-list-without-btn v-if="soft_organigram.is_active" />
		</template>

		<template v-if="soft_organigram.is_active">
			<template v-if="loadedList">
				<template v-if="activityType == 'responsibilities'">
					<div class="data-list activities" v-if="activities && !listEmpty">
						<template v-for="(group, index) in activities">
							<div class="row-space-tbf row-list-item" v-if="selectedGroupResp">
								<div class="space-left"></div>
								<div class="content grouped" @click="handleGroup(index)">
									<div class="group-title" v-if="selectedGroupResp == 'departments_gr_unique_id'">
										<div class="title">
											<div class="name">{{ group[0].departments.names }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupResp == 'status'">
										<div class="title">
											<div class="name">{{ $t(`report_users.responsibility.${group[0].status}`) }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupResp == 'user_id' && group[0].user">
										<div class="title user">
											<img class="avatar" :src="group[0].user.avatar" />
											<div class="name">{{ group[0].user.name }}</div>
										</div>
										<div class="total-number-data">
											{{ $t("report_users.total") }}: {{ activitiesData[index].length }} {{ $t("report_users.responsibilities") }} ,
											{{ activitiesData[index].filter((el) => el.completed === 1).length }} {{ $t("report_users.completed") }}
										</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupResp == 'role_id' && group[0].role">
										<div class="title">
											<div class="name">{{ group[0].role.name }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
								</div>
								<div class="space-right"></div>
							</div>

							<template v-if="!selectedGroupResp || openGroup.includes(index)">
								<div class="row-space-tbf header-list">
									<div class="space-left"></div>
									<div class="content">
										<div class="column-filter column-name">
											<div class="text">{{ $t("general.name") }}</div>
										</div>
										<div v-if="$resize && $mq.above(501)" class="column-filter column-responsible">
											<div class="text">{{ $t("report_users.responsible") }}</div>
										</div>
										<div v-if="$resize && $mq.above(501)" class="column-filter column-deadline">
											<div class="text">{{ $t("report_users.deadline") }}</div>
										</div>
										<div v-if="$resize && $mq.above(700)" class="column-filter column-deadline">
											<div class="text">{{ $t("report_users.evaluation_date") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1200)" class="column-filter column-accomplished">
											<div class="text">{{ $t("report_users.responsibility_details") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1300)" class="column-filter column-role">
											<div class="text">{{ $t("report_users.role") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1040)" class="column-filter column-unaccomplished">
											<div class="text">{{ $t("report_users.departments") }}</div>
										</div>
									</div>
									<div class="space-right"></div>
								</div>

								<template v-for="activity in group">
									<div class="row-space-tbf row-list-item">
										<div class="space-left"></div>
										<div class="content">
											<div class="border-hover" v-if="$resize && $mq.above(781)"></div>

											<div class="column-name-desc column-name">
												<!-- status -->
												<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
													<div
														class="status"
														:class="{
															green: activity.status == 'accomplished',
															red: activity.status == 'unaccomplished',
															blue: activity.status == 'unevaluated',
															yellow: activity.free_day,
														}"
													></div>
													<template slot="popover">
														<div class="simple-text">{{ $t(`report_users.responsibility.${activity.free_day ? "free_day" : activity.status}`) }}</div>
													</template>
												</v-popover>

												<!-- name -->
												<div class="text">
													<div class="name">{{ activity.name }}</div>
												</div>
											</div>

											<!-- responsible -->
											<div v-if="$resize && $mq.above(700)" class="column-simple-text column-responsible">
												<div class="avatar" v-if="activity.user">
													<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
														<img class="small" :src="activity.user.avatar" v-if="activity.user.avatar" />
														<div class="user-circle" v-else>
															<icon-user />
														</div>
														<template slot="popover">
															<div class="simple-text">{{ activity.user.name }}</div>
														</template>
													</v-popover>
												</div>
											</div>

											<!-- Deadline -->
											<div v-if="$resize && $mq.above(501)" class="column-simple-text column-deadline">
												<div class="text">{{ activity.deadline | moment("DD MMM YYYY") }}</div>
											</div>

											<!-- evaluation date -->
											<div v-if="$resize && $mq.above(700)" class="column-simple-text column-deadline">
												<div class="text" v-if="activity.evaluation_date">{{ activity.evaluation_date | moment("DD MMM YYYY") }}</div>
												<div class="text" v-else>Nu are data de evaluare</div>
											</div>

											<!-- detalii resp valoric -->
											<div class="column-simple-text column-accomplished" v-if="$resize && $mq.above(1200)">
												<div class="text" v-if="activity.type == 'value'">
													{{ parseInt(activity.value ? activity.value : 0).toString() }}/{{ parseInt(activity.target).toString() + " " + activity.unity }}
												</div>
												<div v-else class="text">
													Check
												</div>
											</div>

											<!-- role -->
											<div v-if="$resize && $mq.above(1300)" class="column-simple-text column-role">
												<div class="text">{{ activity.role.name }}</div>
											</div>

											<!-- department -->
											<div v-if="$resize && $mq.above(1040)" class="column-simple-text column-unaccomplished">
												<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
													<div class="text">{{ activity.departments.names }}</div>
													<template slot="popover">
														<div class="simple-text">{{ activity.departments.names }}</div>
													</template>
												</v-popover>
											</div>
										</div>
										<div class="space-right"></div>
									</div>
								</template>

								<div class="row-space-tbf view-more" v-if="viewMoreActivities[index] < activitiesData[index].length">
									<div class="space-left"></div>
									<div class="content">
										<button class="btn-view-more" @click="viewMoreData(index)">{{ $t("report_users.view_more") }}</button>
									</div>
									<div class="space-right"></div>
								</div>
							</template>
						</template>
					</div>
					<div class="row-space-tbf list-empty" v-else-if="listEmpty">
						<div class="space-left"></div>
						<div class="content full">
							<div class="title">{{ $t("empty.report-title") }}</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
				<template v-if="activityType == 'tasks'">
					<div class="data-list activities" v-if="activities && !listEmpty">
						<div v-for="(group, index) in activities">
							<div class="row-space-tbf row-list-item" v-if="selectedGroupTasks">
								<div class="space-left"></div>
								<div class="content grouped" @click="handleGroup(index)">
									<div class="group-title" v-if="selectedGroupTasks == 'departments_gr_unique_id'">
										<div class="title">
											<div class="name">{{ group[0].departments.names }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupTasks == 'status'">
										<div class="title">
											<div class="name">{{ $t(`report_users.tasksTypes.${group[0].status}`) }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupTasks == 'user_id' && group[0].user">
										<div class="title user">
											<img class="avatar" :src="group[0].user.avatar" />
											<div class="name">{{ group[0].user.name }}</div>
										</div>
										<div class="total-number-data">
											{{ $t("report_users.total") }}: {{ activitiesData[index].length }} {{ $t("report_users.tasks") }} ,
											{{ activitiesData[index].filter((el) => ["accomplished_after_deadline", "accomplished_before_deadline"].includes(el.status)).length }}
											{{ $t("report_users.completed") }}
										</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupTasks == 'priority'">
										<div class="title">
											<div class="name">{{ group[0].priority ? $t(`report_users.priorityTypes.${group[0].priority}`) : $t("report_users.no.priority") }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupTasks == 'project_id'">
										<div class="title">
											<div class="name" v-if="group[0].project" v-html="group[0].project.name"></div>
											<div class="name" v-else>{{ $t("report_users.no.project") }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
									<div class="group-title" v-else-if="selectedGroupTasks == 'tags_gr_unique_id'">
										<div class="title">
											<div class="name">{{ group[0].tags.gr_names ? group[0].tags.gr_names : $t("report_users.no.tags") }}</div>
										</div>
										<div class="total-number-data">{{ $t("report_users.total_responsibilities") }} {{ activitiesData[index].length }}</div>
										<div class="arrow-collapse" :class="{ open: openGroup.includes(index) }">
											<icon-angle-right />
										</div>
									</div>
								</div>
								<div class="space-right"></div>
							</div>

							<template v-if="!selectedGroupTasks || openGroup.includes(index)">
								<div class="row-space-tbf header-list">
									<div class="space-left"></div>
									<div class="content">
										<div class="column-filter column-name">
											<div class="text">{{ $t("general.name") }}</div>
										</div>
										<div v-if="$resize && $mq.above(501)" class="column-filter column-responsible">
											<div class="text">{{ $t("report_users.responsible") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1450)" class="column-filter column-responsible short">
											<div class="text">{{ $t("report_users.owner") }}</div>
										</div>
										<div v-if="$resize && $mq.above(501)" class="column-filter column-deadline">
											<div class="text">{{ $t("report_users.deadline") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1300)" class="column-filter column-deadline">
											<div class="text">{{ $t("report_users.dateOfEnd") }}</div>
										</div>
										<div v-if="$resize && $mq.above(700)" class="column-filter column-priority">
											<div class="text">{{ $t("report_users.priority") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1150)" class="column-filter column-time">
											<div class="text">{{ $t("report_users.tracked_time") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1150)" class="column-filter column-time">
											<div class="text">{{ $t("report_users.estimated_time") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1400)" class="column-filter column-unaccomplished">
											<div class="text">{{ $t("report_users.project") }}</div>
										</div>
										<div v-if="$resize && $mq.above(1040)" class="column-filter column-unaccomplished">
											<div class="text">{{ $t("report_users.departments") }}</div>
										</div>
									</div>
									<div class="space-right"></div>
								</div>

								<template v-for="activity in group">
									<div class="row-space-tbf row-list-item">
										<div class="space-left"></div>
										<div class="content">
											<div class="border-hover" v-if="$resize && $mq.above(781)"></div>

											<div class="column-name-desc column-name">
												<!-- status -->
												<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
													<div
														class="status"
														:class="{
															green: activity.status == 'accomplished_before_deadline',
															red: activity.status == 'past_unaccomplished',
															blue: activity.status == 'accomplished_after_deadline',
															yellow: activity.status == 'future_unaccomplished' || activity.status == 'open',
															grey: activity.status == 'without_deadline',
														}"
													></div>
													<template slot="popover">
														<div class="simple-text">{{ $t(`report_users.tasksTypes.${activity.status}`) }}</div>
													</template>
												</v-popover>

												<!-- name -->
												<div class="text">
													<div class="name">{{ activity.name }}</div>
												</div>
												<!-- v-if="activity.log_type != 'key_result' && activity.tags" -->
												<div class="tags" v-if="activity.tags">
													<div class="tag" :style="`--color: ${tag.color_code}; --background-color: ${tag.color_code}30`" v-for="tag in activity.tags.list">
														<span class="text">
															{{ tag.name }}
														</span>
													</div>
												</div>
											</div>

											<!-- responsible -->
											<div v-if="$resize && $mq.above(700)" class="column-simple-text column-responsible">
												<div class="avatar" v-if="activity.user">
													<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
														<img class="small" :src="activity.user.avatar" v-if="activity.user.avatar" />
														<div class="user-circle" v-else>
															<icon-user />
														</div>
														<template slot="popover">
															<div class="simple-text">{{ activity.user.name }}</div>
														</template>
													</v-popover>
												</div>
											</div>

											<!-- owner -->
											<div v-if="$resize && $mq.above(1450)" class="column-simple-text column-responsible">
												<div class="avatar" v-if="activity.added_by">
													<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
														<img class="small" :src="activity.added_by.avatar" v-if="activity.added_by.avatar" />
														<div class="user-circle" v-else>
															<icon-user />
														</div>
														<template slot="popover">
															<div class="simple-text">{{ activity.added_by.name }}</div>
														</template>
													</v-popover>
												</div>
											</div>

											<!-- date -->
											<div v-if="$resize && $mq.above(501)" class="column-simple-text column-deadline">
												<div class="text">{{ activity.date | moment("DD MMM YYYY") }}</div>
											</div>

											<!-- deadline -->
											<div v-if="$resize && $mq.above(1300)" class="column-simple-text column-deadline">
												<div class="text">{{ activity.deadline | moment("DD MMM YYYY") }}</div>
											</div>

											<!-- priority -->
											<div :class="`column-simple-text column-priority ${activity.priority ? activity.priority : ''}`" v-if="$resize && $mq.above(501)">
												<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" :disabled="!activity.priority">
													<icon-priority class="icon" />
													<template slot="popover">
														<div class="simple-text">{{ activity.priority }}</div>
													</template>
												</v-popover>
											</div>

											<!-- duration -->
											<div v-if="$resize && $mq.above(1150)" class="column-simple-text column-time">
												<icon-timer class="icon" />
												<div class="text" v-if="activity.duration">{{ activity.duration }}h</div>
												<div v-else>-</div>
											</div>

											<!-- estimation -->
											<div v-if="$resize && $mq.above(1150)" class="column-simple-text column-time">
												<icon-hourglass class="icon" />
												<div class="text" v-if="activity.estimation">{{ activity.estimation }}h</div>
												<div v-else>-</div>
											</div>

											<!-- Projects -->
											<div v-if="$resize && $mq.above(1400)" class="column-simple-text column-unaccomplished">
												<div class="text" v-if="activity.project" v-html="activity.project.name"></div>
											</div>

											<!-- department -->
											<div v-if="$resize && $mq.above(1040)" class="column-simple-text column-unaccomplished">
												<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }">
													<div class="text">{{ activity.departments.names }}</div>
													<template slot="popover">
														<div class="simple-text">{{ activity.departments.names }}</div>
													</template>
												</v-popover>
											</div>
										</div>
										<div class="space-right"></div>
									</div>
								</template>

								<div class="row-space-tbf view-more" v-if="viewMoreActivities[index] < activitiesData[index].length">
									<div class="space-left"></div>
									<div class="content">
										<button class="btn-view-more" @click="viewMoreData(index)">{{ $t("report_users.view_more") }}</button>
									</div>
									<div class="space-right"></div>
								</div>
							</template>
						</div>
					</div>
					<div class="row-space-tbf list-empty" v-else-if="listEmpty">
						<div class="space-left"></div>
						<div class="content full">
							<div class="title">{{ $t("empty.report-title") }}</div>
						</div>
						<div class="space-right"></div>
					</div>
				</template>
			</template>

			<div class="row-space-tbf" v-else>
				<div class="space-left"></div>
				<div class="content">
					<div class="loader-container">
						<div class="loader-report">
							<div class="header-loader">
								<div class="loader-text">{{ $t("report_users.generating_report") }}</div>
								<div class="loader"></div>
							</div>
							<div class="progress-bar">
								<div class="progress" :class="{ loading: startLoading }" id="progress-bar"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<!-- <loader-items-list /> -->
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
import NotModule from "@/components/General/NotModule";
import IconArrow from "../../Icons/Arrow";
import IconAngleRight from "../../Icons/AngleRight";
import IconTrash from "../../Icons/Trash";
import IconClose from "../../Icons/Close";
import IconUser from "../../Icons/User";
import IconPeople from "../../Icons/User";
import IconSearch from "../../Icons/Search";
import IconDate from "../../Icons/Date";
import LoaderItemsList from "../../PagesLoaders/ItemsList";
import LoaderHeaderList from "../../PagesLoaders/HeaderList";
import LoaderHeaderListWithoutBtn from "../../PagesLoaders/HeaderListWithoutBtn";
import IconDownload from "../../Icons/Download";
import IconSettings from "../../Icons/Settings";
import IconInfo from "../../Icons/Info";
import IconTask from "../../Icons/Repeat";
import IconPriority from "../../Icons/Flag";
import IconTimer from "../../Icons/Timer";
import IconHourglass from "../../Icons/Hourglass";

export default {
	components: {
		NotModule,
		IconArrow,
		IconAngleRight,
		IconUser,
		IconPeople,
		IconClose,
		IconSearch,
		LoaderItemsList,
		LoaderHeaderList,
		LoaderHeaderListWithoutBtn,
		IconDownload,
		IconDate,
		IconTrash,
		IconSettings,
		IconInfo,
		IconTask,
		IconPriority,
		IconTimer,
		IconHourglass,
	},
	data() {
		return {
			loaded: false,
			loadedList: false,
			activitiesData: [],
			viewMoreActivities: {},
			search_filter: "",
			queryFiltersCount: 0,
			extendFilters: true,
			dateRange: {
				startDate: moment().add(-30, "days"),
				endDate: moment(),
			},
			calendarFilterSettings: {
				firstDay: 1,
				format: "DD-MM-YYYY",
				daysOfWeek: moment.weekdaysMin(),
				monthNames: moment.monthsShort(),
			},
			statusListResponsibilities: ["accomplished", "unaccomplished", "unevaluated"],
			selectedStatusResponsibilities: [],
			statusListTasks: ["accomplished_before_deadline", "accomplished_after_deadline", "without_date", "past_unaccomplished", "open"],
			selectedStatusTasks: [],
			activitiesList: ["responsibilities", "tasks"],
			activityType: "responsibilities",
			filterDepartments: [],
			selectedDepartments: [],
			searchDepartments: "",
			filterUsers: [],
			selectedUsers: [],
			searchUsers: "",
			respGroupBy: ["status", "user_id", "role_id", "departments_gr_unique_id"],
			selectedGroupResp: "",
			tasksGroupBy: ["status", "user_id", "priority", "departments_gr_unique_id", "project_id", "tags_gr_unique_id"],
			selectedGroupTasks: "",
			listEmpty: false,
			openGroup: [],
			startLoading: false,
		};
	},
	watch: {
		search_filter: function(val) {
			var data = Object.assign({}, this.$route.query);
			data["search"] = this.search_filter;

			if (this.search_filter == "") {
				delete data["search"];
			}

			this.$router.push({ query: data });
		},
		dateRange: function(val) {
			var data = Object.assign({}, this.$route.query);
			data["startDate"] = moment(this.dateRange.startDate).format("DD-MM-YYYY");
			data["endDate"] = moment(this.dateRange.endDate).format("DD-MM-YYYY");

			this.$router.push({ query: data });
			this.getActivities();
		},
	},
	computed: {
		filteredDepartments() {
			return getByKeywordFilter(this.filterDepartments, this.searchDepartments);
		},
		soft_organigram() {
			return this.$store.getters["applications/getApplication"]("Organigrama");
		},
		filteredUsers() {
			return getByKeywordFilter(this.filterUsers, this.searchUsers);
		},
		activities() {
			if (Object.keys(this.activitiesData).length > 0) {
				return Object.keys(this.activitiesData)
					.map((key) => {
						return {
							[key]: { ...this.activitiesData }[key].slice(0, this.viewMoreActivities[key]),
						};
					})
					.reduce((obj, item) => {
						const key = Object.keys(item)[0];
						obj[key] = item[key];
						return obj;
					}, {});
			} else {
				return {};
			}
		},
	},
	async mounted() {
		if (this.soft_organigram.is_active) {
			await this.getFilters();
		} else {
			this.loaded = true;
		}

		await this.checkQueryFilters();
		await this.getActivities();
	},
	methods: {
		async getFilters() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, {
					params: {
						departments_level: true,
						users: true,
					},
				})
				.then(({ data }) => {
					this.filterDepartments = data.data.departments_level;
					this.filterUsers = data.data.users;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		async getActivities() {
			var sortDirection = "desc";
			if (this.selectedGroupResp == "status" || this.selectedGroupTasks == "status") {
				sortDirection = "asc";
			} else if (this.selectedGroupTasks == "priority" || this.selectedGroupTasks == "project_id" || this.selectedGroupTasks == "tags_gr_unique_id") {
				sortDirection = "desc";
			}

			var paramsCall = {
				start_interval: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
				end_interval: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
				log_type: this.activityType ? this.activityType : "responsibilities",
			};

			if (sortDirection) {
				paramsCall.sort_direction = sortDirection;
			}

			this.loadedList = false;
			this.openGroup = [];
			paramsCall = this.queryObject(paramsCall);

			setTimeout(() => {
				this.startLoading = true;
			}, 200);

			await axios
				.get("/reports/" + this.$auth.user().instance.id + "/global-activity", {
					params: paramsCall,
				})
				.then(({ data }) => {
					this.activitiesData = data.data;
					this.parseArraysWithFrontendViewMore();

					if ((Array.isArray(data.data) && data.data.length == 0) || (data.data.hasOwnProperty("all") && data.data.all.length == 0) || Object.keys(data.data).length == 0) {
						this.listEmpty = true;
					} else {
						this.listEmpty = false;
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loadedList = true;
					this.startLoading = false;
				});
		},
		queryObject(paramsCall) {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					paramsCall.search = dataQuery[key];
				}
				if (key == "status") {
					paramsCall.status = dataQuery[key].split(",");
				}
				if (key == "departments") {
					paramsCall.departments_ids = dataQuery[key].split(",").map(Number);
				}
				if (key == "users") {
					paramsCall.users_ids = dataQuery[key].split(",");
				}
				if (key == "group_by") {
					paramsCall.group_by = dataQuery[key];
				}
			});

			return paramsCall;
		},
		donwloadXLS() {
			var showColumnsObj = ["name", "email", "departments", "responsibilities.total", "responsibilities.accomplished", "responsibilities.unaccomplished"];

			var paramsCall = {};
			paramsCall = this.queryObject(paramsCall);
			paramsCall.type = "xls";
			paramsCall.show_columns = showColumnsObj;

			paramsCall.start_date = moment(this.dateRange.startDate).format("YYYY-MM-DD");
			paramsCall.end_date = moment(this.dateRange.endDate).format("YYYY-MM-DD");

			axios
				.get("/reports/" + this.$auth.user().instance.id + "/people", {
					params: paramsCall,
					responseType: "blob",
				})
				.then((response) => {
					let blob = new Blob([response.data], { type: "application/octet-stream" });
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t("report_users.name_report_xls_users") + ".xlsx";

					let fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", fileName);
					document.body.appendChild(fileLink);

					fileLink.click();
				});
		},
		checkQueryFilters() {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					this.search_filter = dataQuery[key];
				}
				if (key == "log_type") {
					this.activityType = dataQuery[key];
				}
				if (key == "startDate") {
					this.dateRange.startDate = moment(dataQuery[key], "DD-MM-YYYY");
				}
				if (key == "endDate") {
					this.dateRange.endDate = moment(dataQuery[key], "DD-MM-YYYY");
				}
				if (this.activityType == "responsibilities") {
					if (key == "status") {
						this.selectedStatusResponsibilities = dataQuery[key];
					}
					var dataType = "";
					switch (dataQuery[key]) {
						case "status":
							dataType = "status";
							break;
						case "user.id":
							dataType = "user_id";
							break;
						case "role.id":
							dataType = "role_id";
							break;
						case "departments.gr_unique_id":
							dataType = "departments_gr_unique_id";
							break;
					}
					if (key == "group_by") {
						this.selectedGroupResp = dataType;
					}
				} else {
					if (key == "status") {
						this.selectedStatusTasks = dataQuery[key];
					}
					var dataTypeTask = "";
					switch (dataQuery[key]) {
						case "status":
							dataTypeTask = "status";
							break;
						case "user.id":
							dataTypeTask = "user_id";
							break;
						case "priority":
							dataTypeTask = "priority";
							break;
						case "departments.gr_unique_id":
							dataTypeTask = "departments_gr_unique_id";
							break;
						case "project.id":
							dataTypeTask = "project_id";
							break;
						case "tags.gr_unique_id":
							dataTypeTask = "tags_gr_unique_id";
							break;
					}
					if (key == "group_by") {
						this.selectedGroupTasks = dataTypeTask;
					}
				}
				if (key == "departments") {
					this.selectedDepartments = dataQuery[key].split(",").map(Number);
				}
				if (key == "users") {
					this.selectedUsers = dataQuery[key].split(",");
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;
		},
		searchFilterFunct() {
			this.loadedList = false;
			this.getActivities();
		},
		selectOptionFilter(filter, value, customValue = false) {
			// Custom functionality, check all childrens departments
			if (customValue) {
				var arrayTreeDepartments = [];
				arrayTreeDepartments.push(value);
				arrayTreeDepartments = this.getAllChildrensDepartments(customValue, arrayTreeDepartments);

				if (this[filter].includes(parseInt(value))) {
					arrayTreeDepartments.map((item) => {
						if (this[filter].includes(parseInt(item))) {
							this[filter].splice(this[filter].indexOf(parseInt(item)), 1);
						}
					});
				} else {
					arrayTreeDepartments.map((item) => {
						if (!this[filter].includes(parseInt(item))) {
							this[filter].push(parseInt(item));
						}
					});
				}
			} else {
				if (typeof value == "string") {
					if (this[filter].includes(value)) {
						this[filter].splice(this[filter].indexOf(value), 1);
					} else {
						this[filter].push(value);
					}
				} else {
					if (this[filter].includes(parseInt(value))) {
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1);
					} else {
						this[filter].push(parseInt(value));
					}
				}
			}

			this.addFilterToRouteQuery(filter);
		},
		selectSingleOptionFilter(filter, value) {
			if (this[filter] != value) {
				this[filter] = value;
			} else {
				this[filter] = "";
			}

			this.addFilterToRouteQuery(filter);
		},
		clearFilter(filter, type, withoutAddFilter = false) {
			if (filter == "all") {
				this.selectedStatusTasks = [];
				this.selectedStatusResponsibilities = [];
				this.selectedDepartments = [];
				this.selectedUsers = [];
				this.selectedGroupResp = "";
				this.selectedGroupTasks = "";
			} else {
				this[filter] = [];
				if (type == "string") {
					this.selectedGroupResp = "";
					this.selectedGroupTasks = "";
				}
			}

			if (!withoutAddFilter) {
				this.addFilterToRouteQuery(filter);
			}
		},
		addFilterToRouteQuery(filterName) {
			var dataQuery = Object.assign({}, this.$route.query);

			if (filterName == "selectedStatusResponsibilities" || filterName == "all") {
				dataQuery["status"] = this.selectedStatusResponsibilities.toString();
			}
			if (filterName == "selectedStatusTasks" || filterName == "all") {
				dataQuery["status"] = this.selectedStatusTasks.toString();
			}
			if (filterName == "selectedDepartments" || filterName == "all") {
				dataQuery["departments"] = this.selectedDepartments.toString();
			}
			if (filterName == "selectedUsers" || filterName == "all") {
				dataQuery["users"] = this.selectedUsers.toString();
			}
			if (filterName == "activityType" || filterName == "all") {
				dataQuery["log_type"] = this.activityType;
			}

			var dataType = "";
			switch (this.selectedGroupResp) {
				case "status":
					dataType = "status";
					break;
				case "user_id":
					dataType = "user.id";
					break;
				case "role_id":
					dataType = "role.id";
					break;
				case "departments_gr_unique_id":
					dataType = "departments.gr_unique_id";
					break;
			}

			if (filterName == "selectedGroupResp" || filterName == "all") {
				dataQuery["group_by"] = dataType;
			}

			var dataTypeTask = "";
			switch (this.selectedGroupTasks) {
				case "status":
					dataTypeTask = "status";
					break;
				case "user_id":
					dataTypeTask = "user.id";
					break;
				case "priority":
					dataTypeTask = "priority";
					break;
				case "departments_gr_unique_id":
					dataTypeTask = "departments.gr_unique_id";
					break;
				case "project_id":
					dataTypeTask = "project.id";
					break;
				case "tags_gr_unique_id":
					dataTypeTask = "tags.gr_unique_id";
					break;
			}

			if (filterName == "selectedGroupTasks" || filterName == "all") {
				dataQuery["group_by"] = dataTypeTask;
			}

			Object.keys(dataQuery).map(function(key, value) {
				if (dataQuery[key] == "") {
					delete dataQuery[key];
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;

			this.$router.push({ query: dataQuery });

			this.getActivities();
		},
		getAllChildrensDepartments(department, arrayIds) {
			if (this.filterDepartments.filter((el) => el.related_to == department.id).length) {
				this.filterDepartments
					.filter((el) => el.related_to == department.id)
					.map((dep) => {
						arrayIds.push(dep.id);
						arrayIds = this.getAllChildrensDepartments(dep, arrayIds);
					});
			}

			return arrayIds;
		},
		changeActivityType(type) {
			this.loadedList = false;
			this.queryFiltersCount = 1;

			if (type == "responsibilities") {
				this.activityType = "responsibilities";
			} else if (type == "tasks") {
				this.activityType = "tasks";
			}

			this.clearFilter("all", null, true);

			this.$nextTick(() => {
				this.addFilterToRouteQuery("activityType");
			});
		},
		parseArraysWithFrontendViewMore() {
			if (Object.keys(this.activitiesData).length > 0) {
				Object.keys(this.activitiesData).map((key) => {
					if (key === "all") {
						this.viewMoreActivities[key] = 40;
					} else {
						this.viewMoreActivities[key] = 1;
					}
				});
			}
		},
		viewMoreData(key) {
			var currentViewMore = this.viewMoreActivities[key];
			var lengthArray = this.activitiesData[key].length;

			if (lengthArray - currentViewMore >= 40) {
				this.$set(this.viewMoreActivities, key, currentViewMore == 1 ? 40 : currentViewMore + 40);
			} else {
				this.$set(this.viewMoreActivities, key, currentViewMore + (lengthArray - currentViewMore));
			}

			this.activities[key] = { ...this.activitiesData }[key].slice(0, this.viewMoreActivities[key]);

			this.$nextTick(() => {
				this.$forceUpdate();
			});
		},
		handleGroup(key) {
			if (this.openGroup.includes(key)) {
				this.openGroup.splice(this.openGroup.indexOf(key), 1);
				this.viewMoreActivities[key] = 1;
			} else {
				this.openGroup.push(key);
				this.viewMoreData(key);
			}
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>

<style lang="scss" scoped>
.row-space-tbf.search-row {
	&.reports {
		padding-top: 0;
	}
}

.actions.report-users {
	.filter-departments {
		max-width: 200px;
	}
}
.list-empty .content {
	padding: 10px;
	padding-left: 0px;
	border-top: none;
	color: $grey;
}

.vue-daterange-picker {
	&.calendar-filter {
		&.report-calendar {
			.form-control {
				height: 40px;
				.input-picker {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					.icon {
						margin-right: 10px;
						justify-content: center;
						display: flex;
						svg {
							height: 16px;
							width: auto;
							.fill-color {
								fill: $placeholderColor;
							}
						}
					}
					.text {
						color: $placeholderColor;
					}
					.date {
						flex: 1;
						font-size: 12px;
						color: $primary;
						text-transform: capitalize;
						line-height: normal;
					}
				}
			}
		}
	}
}
</style>
